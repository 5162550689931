.spinner48 {
    font-size: 48px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.magnifier {
    font-size: 20px;
    background-color: white;
    padding: 2px;
}
.fade-in-class {
    animation-name: fade-in;
    animation-duration: 1s;
  }
  .fade-out-class {
    animation-name: fade-out;
    animation-duration: 1s;
  }
  @keyframes fade-in {
    from { opacity: 0.0; }
    to   { opacity: 1.0; }
  }
  
  @keyframes fade-out {
    from { opacity: 1.0; }
    to   { opacity: 0.0; }
    }
  